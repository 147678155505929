<template>
  <div>
     <Row :gutter="5" class="m-b-5">
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" placeholder="档期开始时间" v-model="statementChangeQuery.startDate" style="width:100%"></DatePicker>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" placeholder="档期结束时间" v-model="statementChangeQuery.endDate" style="width:100%"></DatePicker>
      </i-col>
      <i-col  v-if="isAuth('StatementChange_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
        <Select size="small" v-model="statementChangeQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
        </Select>
      </i-col>
       <i-col  v-if="isAuth('StatementChange_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="statementChangeQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
      <i-col v-if="isAuth('StatementChange_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
        <Select size="small" v-model="statementChangeQuery.userId" placeholder="归属销售" clearable>
          <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
        </Select>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-select size="small" placeholder="状态" v-model="statementChangeQuery.status" multiple>
          <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持结算单名称/编号/甲乙方查询" v-model="statementChangeQuery.keyword">
        </i-input>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-button size="small"  type="primary"  icon="ios-search"  @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>
    <!-- 变更列表 -->
    <i-table stripe  :data="list" :columns="titles"></i-table>
    <div class="paging_style">
        <Page size="small" :total="total"  :current="statementChangeQuery.pageNumber" :page-size="statementChangeQuery.pageSize"  @on-change="changePage" show-total show-elevator ></Page>
    </div>
  </div>
</template>

<script>
import { getStatementChangePage, deleteVersion } from '@/api/scp/statementChange'
import { GetCurrentSchedule } from '@/utils/dateFormat.js'
import { formatStatementStatus } from '@/utils/tagStatus'
import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  data () {
    return {
      channellist: [],
      departments: [],
      channelUsers: [],
      total: 15,
      list: [],
      titles: [
        { title: '编码', key: 'code' },
        { title: '广告主', key: 'advertiserName' },
        { title: '品牌', key: 'name' },
        {
          title: '起止日期',
          render: (h, data) => {
            return h('div', [
              h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
              // h('span', { style: { color: '#ee7c4b' } }, '(' + GetPublishDay(data.row.startDate, data.row.endDate) + ')')
            ])
          }
        },
        {
          title: '最后更新时间',
          key: 'updateTime'
        },
        {
          title: '状态',
          align: 'center',
          width: 110,
          render: (h, data) => {
            return formatStatementStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const editableStatus = [0, 6]
            const updateButton = h('a', {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.handleUpdate(data.row)
                }
              }
            }, '编辑')

            const deleteButton = h('a', {
              style: { 'margin-left': '5px', color: '#ef4f4f' },
              on: {
                click: () => {
                  this.handleDelete(data.row)
                }
              }
            }, '删除')

            const detailButton = h('a', {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.showDetail(data.row)
                }
              }
            }, '详情')

            // 开始返回可用的操作按钮
            if (this.isAuth('StatementChange_Edit') && editableStatus.includes(data.row.status)) {
              return h('div', [detailButton, updateButton, deleteButton])
            }
            return h('div', [detailButton])
          }
        }
      ],
      statusArray: []
    }
  },
  methods: {
    initPage () {
      this.channellist = []
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.statementChangeQuery.companyId) {
          if (array.length > 1 && this.isAuth('StatementChange_Manage_View')) {
            this.statementChangeQuery.companyId = null
          } else {
            this.statementChangeQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }

        this.initdepartments()
      })
    },
    companyChange () {
      this.statementChangeQuery.departmentId = ''
      this.statementChangeQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.statementChangeQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.statementChangeQuery.companyId) {
        getCompanyChild({ companyId: this.statementChangeQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          // this.statementChangeQuery.departmentId = null
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.channelUsers = []
      const data = {
        companyId: this.statementChangeQuery.departmentId || this.statementChangeQuery.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.statementChangeQuery.userId = ''
      }
      this.getPage()
    },
    getPage () {
      // this.statementChangeQuery.statusList = (this.queryStatus.length > 0) ? JSON.stringify(this.queryStatus) : ''
      if (this.statementChangeQuery.status.length > 0) {
        this.statementChangeQuery.statusList = JSON.stringify(this.statementChangeQuery.status)
      } else {
        this.statementChangeQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      this.$store.commit('set_query_statementChangeQuery', this.statementChangeQuery)
      getStatementChangePage(this.statementChangeQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    changePage (page) {
      this.statementChangeQuery.pageNumber = page
      this.getPage()
    },
    handleSearch () {
      this.statementChangeQuery.pageNumber = 1
      this.getPage()
    },
    showDetail (statement) {
      this.$emit('on-detail', statement.statementId, statement.versionId)
    },
    handleDelete (statement) {
      this.$Modal.confirm({
        title: '您是否要删除该结算单？',
        content: '删除该结算单后，您可能需要重新创建一个',
        onOk: () => {
          this.deleteStatementChange(statement)
        }
      })
    },
    deleteStatementChange (statement) {
      deleteVersion({ versionId: statement.versionId }).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ title: '删除成功', desc: '您已成功删除了一个结算单变更请求！' })
          this.statementChangeQuery.pageNumber = 1
          this.getPage()
        }
      })
    },
    handleUpdate (statement) {
      this.$emit('on-edit', statement.statementId, statement.versionId)
    },
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: -1, name: '已废弃' },
        { id: 0, name: '草稿' }
      ]
      const manageStatus = [
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
        // { id: 9, name: '已归档' }
      ]
      if (that.isAuth('StatementChange_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    }

  },
  created () {
    this.statementChangeQuery.userId = this.isAuth('StatementChange_Manage_View') ? this.statementChangeQuery.userId : this.$store.getters.token.userInfo.userId
    this.initStatusArray()
    this.initPage()
  },
  computed: {
    statementChangeQuery () {
      return this.$store.state.contractQuery.statementChangeQuery
    }
  }

}
</script>
